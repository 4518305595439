import { concat, of } from 'rxjs';
import { map, switchMap, catchError, debounceTime } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

import { HISTORY_INIT, HISTORY_PAGINATE, HISTORY_FILTER, HISTORY_GET_DETAILS, historyUpdate } from '../actions';

let endpoint = `${window.location.origin}/`;
if (process.env.NODE_ENV === 'development') {
    endpoint = process.env.REACT_APP_API_URL;
}

export const historyInitEpic = (action$, store) =>
    action$.ofType(HISTORY_INIT).pipe(
        switchMap((action) => {
            // Tells application that history is loading, and starts fetching history list and manual list.
            return concat(
                of(historyUpdate({ loading: true })),
                ajax
                    .post(
                        `${endpoint}api/invoice/list`,
                        {
                            filter: { ...store.value.history.filter },
                            query: store.value.history.query,
                            size: store.value.history.size,
                            page: 1,
                        },
                        {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                            'organisation-number': store.value.organisation.organisationNumber,
                        }
                    )
                    .pipe(
                        map((ajaxResponse) => ajaxResponse.response),
                        map((data) => {
                            return historyUpdate({ ...data, loading: false });
                        }),
                        catchError((error) => {
                            console.log("Couldn't fetch invoices", error);
                            return of(historyUpdate({ error: true, loading: false }));
                        })
                    )
            );
        })
    );

export const historyPaginateEpic = (action$, store) =>
    action$.ofType(HISTORY_PAGINATE).pipe(
        debounceTime(10),
        switchMap((action) => {
            // Create filter object based on store filter data, and increase the page size by 1
            let filter = {
                filter: { ...store.value.history.filter },
                query: store.value.history.query,
                size: store.value.history.size,
                page: store.value.history.page + 1,
            };
            // Fetch new content using the filter.
            return concat(
                of(historyUpdate({ loading: true })),
                ajax
                    .post(`${endpoint}api/invoice/list`, filter, {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                        'organisation-number': store.value.organisation.organisationNumber,
                    })
                    .pipe(
                        map((ajaxResponse) => ajaxResponse.response),
                        map((data) => {
                            let invoices = [...store.value.history.invoices, ...data.invoices];
                            let aggregations = data.aggregations;
                            // Update store data
                            return historyUpdate({ invoices: invoices, aggregations: aggregations, ...filter, loading: false });
                        }),
                        catchError((error) => {
                            // HTTP error happened
                            console.log("Couldn't fetch invoices");
                            return of(historyUpdate({ error: true, loading: false }));
                        })
                    )
            );
        })
    );

export const historyFilterEpic = (action$, store) =>
    action$.ofType(HISTORY_FILTER).pipe(
        switchMap((action) => {
            // Create filter object based on store filter data, but force it to load first page
            let filter = {
                filter: action.payload.filter,
                query: action.payload.query,
                size: store.value.history.size,
                page: 1,
            };
            if (filter.filter && filter.filter.states && filter.filter.states.length === 22) {
                //if all states are sent then send null to decrease payload. Same result
                filter.filter.states = null;
            }
            return concat(
                of(historyUpdate({ loading: true, invoices: [] })),
                ajax
                    .post(`${endpoint}api/invoice/list`, filter, {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                        'organisation-number': store.value.organisation.organisationNumber,
                    })
                    .pipe(
                        map((ajaxResponse) => ajaxResponse.response),
                        map((data) => {
                            return historyUpdate({ ...data, ...filter, loading: false });
                        }),
                        catchError((error) => {
                            console.log("Couldn't fetch invoices");
                            return of(historyUpdate({ error: true, loading: false }));
                        })
                    )
            );
        })
    );

export const historyGetDetailsEpic = (action$, store) =>
    action$.ofType(HISTORY_GET_DETAILS).pipe(
        switchMap((action) => {
            return concat(
                of(historyUpdate({ loading: true })),
                ajax
                    .post(
                        `${endpoint}api/invoice/details`,
                        {
                            invoiceId: action.payload,
                        },
                        {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                            'organisation-number': store.value.organisation.organisationNumber,
                        }
                    )
                    .pipe(
                        map((response) => {
                            if (response.status === '204') {
                                // Server responded with no content, so i guess nothing should happen.
                                return historyUpdate({ details: null, loading: false });
                            }
                            //Local copy kept in order to replace strings below in order to being able to parse strings to floats if needed
                            let data = { ...response.response };
                            if (response.response && response.response.debitInformation) {
                                data.debitInformation = {
                                    ...data.debitInformation,
                                    depositionUsage: response.response.debitInformation.depositionUsage.replace(',', '.'),
                                    commissionFee: response.response.debitInformation.commissionFee.replace(',', '.'),
                                    commissionRate: response.response.debitInformation.commissionRate.replace(',', '.'),
                                    administrativeFees: response.response.debitInformation.administrativeFees.replace(',', '.'),
                                };
                            }
                            return historyUpdate({ details: data, loading: false });
                        }),
                        catchError((error) => {
                            console.log("Couldn't fetch invoice details", error);
                            return of(historyUpdate({ error: true, loading: false }));
                        })
                    )
            );
        })
    );
