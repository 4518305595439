import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import queryString from 'query-string';

import Icon from '../../../global/Icon';
import Spinner from '../../../global/Spinner';
import DayPickerInput from '../../../global/inputs/DayPickerInput';
import RangeSlider from '../../../global/inputs/RangeSlider';
import { stateMapper } from '../../../global/State';

import { historyFilter } from '../../../actions';

import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

class HistoryInvoiceFilter extends Component {
    constructor(props) {
        super(props);

        var query = this.props.location?.search ? queryString.parse(this.props.location.search, { arrayFormat: 'comma' }) : null;

        const { activeStates, stateArr, filteredStates, amountStates } = this.getInitStates(query);

        this.state = {
            filterIsDirty: false,
            availableStateClasses: ['function', 'approve', 'wait', 'warning'],
            activeStates: activeStates,
            allStates: stateArr,
            numberOfStatesAvailableByDefault: 4, // This is the length of activeStates and is kept as a property here mostly so that we all know where this comes from.
            filteredStates: filteredStates,
            filter: null,
            showAllStatuses: false,
            defaultNumberOfStates: amountStates,
        };

        this.doResetSubject = new Subject();
        this.doFilterSubject = new Subject().pipe(debounceTime(250));
        this.subscriber = [];
        this.subscriber.push(
            this.doFilterSubject.subscribe(({ query, filter }) => {
                this.setState((state) => {
                    state.filterIsDirty = true;
                    return state;
                });
                this.props.historyFilter(query, filter);
            })
        );

        this.handleAdvancedClick = this.handleAdvancedClick.bind(this);
        this.handleFilterStatusChange = this.handleFilterStatusChange.bind(this);
        this.toggleStateAfterFilterChange = this.toggleStateAfterFilterChange.bind(this);
        this.isOptionSelected = this.isOptionSelected.bind(this);
        if (query) {
            this.initWithQuery(activeStates, filteredStates);
        }
    }
    getInitStates(query) {
        const stateArr = [];
        const filteredStates = [];
        const amountStates = {};
        const usedStates = {};

        for (let stateType in stateMapper) {
            const state = { ...stateMapper[stateType] };
            stateArr.push({ stateType: stateType, ...state });
            amountStates[state.class] = (amountStates[state.class] || 0) + 1;
            if (query?.status) {
                if (query.status.includes(stateType)) {
                    filteredStates.push({ stateType: stateType, ...state });
                    usedStates[state.class] = (usedStates[state.class] || 0) + 1;
                }
            } else {
                filteredStates.push({ stateType: stateType, ...state });
                usedStates[state.class] = (usedStates[state.class] || 0) + 1;
            }
        }

        const activeStates = [];
        for (let usedState in usedStates) {
            if (amountStates[usedState] === usedStates[usedState]) {
                activeStates.push(usedState);
            }
        }
        return { activeStates, stateArr, filteredStates, amountStates };
    }
    initWithQuery(activeStates, filteredStates) {
        let filter = { ...this.props.history.filter };
        filter.states = 4 === activeStates.length ? null : filteredStates && filteredStates.length > 0 ? filteredStates.map((s) => s.stateType) : null;
        this.props.historyFilter(this.props.history.query, filter);
    }

    componentDidUpdate(prevProps, prevState) {
        let historyHasNotChanged = JSON.stringify(prevProps.history) === JSON.stringify(this.props.history);
        let filterHasNotChanged = this.state.filter && JSON.stringify(this.state.filter) === JSON.stringify(prevState.filter);
        let storeFilterHasNotChanged = JSON.stringify(this.state.filter) === JSON.stringify(this.props.history.aggregations.global);

        if (historyHasNotChanged || (filterHasNotChanged && storeFilterHasNotChanged)) {
            // Nothing of interest has changed
            return;
        }
        this.setState((state) => {
            // Inherit filter from global aggregation
            state.filter = this.props.history.aggregations.global;
            return state;
        });
    }
    componentWillUnmount() {
        this.subscriber.forEach((sub) => {
            sub.unsubscribe();
        });
    }
    getQueryStates(searchQuery, queryTerm) {
        const queries = searchQuery.split('&');
        for (var i = 0; i < queries.length; i++) {
            var search = queries[i].split('=');
            if (search[0] === queryTerm) {
                return search[1].split(',');
            }
        }
        return null;
    }
    handleFilterUpdate(propertyName, type, state) {
        let filter = { ...this.props.history.filter };
        let filterValue = { ...filter[propertyName] };
        switch (propertyName) {
            case 'invoiceAmountRange':
                filterValue = {
                    min: state.min,
                    max: state.max,
                };
                break;
            case 'invoiceDate':
            case 'dueDate':
            case 'createdDate':
                filterValue[type] = state.selectedDay;
                break;
            case 'states':
            default:
                break;
        }

        filter[propertyName] = filterValue;
        filter.states =
            this.state.numberOfStatesAvailableByDefault === this.state.activeStates.length
                ? null
                : this.state.filteredStates && this.state.filteredStates.length > 0
                ? this.state.filteredStates.map((s) => s.stateType)
                : null;

        const query = this.props.history.query;
        this.doFilterSubject.next({ query: query, filter: filter });
    }
    toggleState(className, event) {
        const stateArr = this.state.allStates;
        const findFilteredStates = (state, stateArr) => {
            let arr = [];
            stateArr.forEach((stateObj) => {
                if (state.activeStates.indexOf(stateObj.class) > -1) {
                    arr.push(stateObj);
                }
            });

            return arr;
        };
        this.setState(
            (state) => {
                if (this.state.activeStates.indexOf(className) > -1) {
                    state.activeStates.splice(this.state.activeStates.indexOf(className), 1);
                } else {
                    state.activeStates.push(className);
                    if(this.state.activeStates.indexOf('activeUnpaid') > -1) {
                        state.activeStates.splice(this.state.activeStates.indexOf('activeUnpaid'), 1);
                    } else if (this.state.activeStates.indexOf('pendingRepurchasing') > -1) {
                        state.activeStates.splice(this.state.activeStates.indexOf('pendingRepurchasing'), 1);
                    }
                }

                state.filteredStates = findFilteredStates(state, stateArr);
                return state;
            },
            () => {
                this.handleFilterUpdate('states', 'toggleList');
            }
        );
    }
    toggleStateSpecific(className, states, event) {
        this.setState(
            (state) => {
                if(this.state.activeStates.indexOf(className) > -1) {
                    state.activeStates = [];
                    state.filteredStates = [];
                } else {
                    state.activeStates = [className];
                    state.filteredStates = this.state.allStates.filter((state) => {
                        return states.indexOf(state.stateType) > -1;
                    });
                }
                
                return state;
            },
            () => {
                this.handleFilterUpdate('states', 'toggleList');
            }
        );
    }
    clearFilter(event) {
        event.preventDefault();
        this.setState(
            {
                filterIsDirty: false,
                activeStates: ['function', 'approve', 'wait', 'warning'],
                filteredStates: this.state.allStates,
            },
            () => {
                this.doResetSubject.next();
                this.props.historyFilter(null, {
                    dueDate: {
                        from: null,
                        to: null,
                    },
                    invoiceDate: {
                        from: null,
                        to: null,
                    },
                    createdDate: {
                        from: null,
                        to: null,
                    },
                    invoiceAmountRange: {
                        min: null,
                        max: null,
                    },
                    states: null,
                });
            }
        );
    }
    handleAdvancedClick(event) {
        event.preventDefault();

        this.setState({
            showAllStatuses: !this.state.showAllStatuses,
        });
    }
    handleFilterStatusChange(newValue, action) {
        this.setState(
            {
                filteredStates: newValue,
            },
            () => {
                this.toggleStateAfterFilterChange();
            }
        );
    }
    toggleStateAfterFilterChange() {
        const allClasses = this.state.availableStateClasses;
        const filteredStates = this.state.filteredStates;

        this.setState(
            (state) => {
                allClasses.forEach((stateClass) => {
                    let count = 0;
                    filteredStates.forEach((filteredState) => {
                        if (filteredState.class === stateClass) {
                            count++;
                        }
                    });

                    const index = this.state.activeStates.indexOf(stateClass);
                    if (count !== this.state.defaultNumberOfStates[stateClass] && index > -1) {
                        state.activeStates.splice(index, 1);
                    } else if (count === this.state.defaultNumberOfStates[stateClass] && index === -1) {
                        state.activeStates.push(stateClass);
                    }
                });
                return state;
            },
            () => {
                this.handleFilterUpdate('states', 'toggleList');
            }
        );
    }
    isOptionSelected(data) {
        const index = this.state.filteredStates.findIndex((state) => {
            return state.stateType === data.stateType;
        });
        return index > -1;
    }

    render() {
        const advanceLinkText = this.state.showAllStatuses ? 'Dölj avancerat...' : 'Visa avancerat...';
        const textColor = 'rgba(white, 0.9)';
        const bgColor = '#495569';
        const highlightColor = '#2b3340';
        const functionColor = '#3292ba';
        const waitColor = '#f5b13e';
        const approvedColor = '#179e5a';
        const warningColor = '#ce2066';

        const colourStyles = {
            menu: (styles) => ({ ...styles, backgroundColor: '#495569' }),
            control: (styles, { data }) => ({
                ...styles,
                backgroundColor: 'white',
            }),
            multiValueLabel: (styles, { data }) => ({
                ...styles,
                width: '12rem',
                color: 'white',
            }),
            multiValue: (styles, { data }) => {
                const dataColor =
                    data.class === 'function' ? functionColor : data.class === 'wait' ? waitColor : data.class === 'warning' ? warningColor : approvedColor;
                return {
                    ...styles,
                    backgroundColor: bgColor,
                    color: 'white',
                    ':before': {
                        backgroundColor: dataColor,
                        borderRadius: 10,
                        marginRight: '10px',
                        content: '" "',
                        display: 'block',
                        height: 'auto',
                        width: 10,
                    },
                };
            },
            multiValueRemove: (styles) => ({
                ...styles,
                ':hover': {
                    backgroundColor: warningColor,
                    color: 'white',
                },
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                const dataColor =
                    data.class === 'function' ? functionColor : data.class === 'wait' ? waitColor : data.class === 'warning' ? warningColor : approvedColor;
                return {
                    ...styles,
                    backgroundColor: isDisabled ? bgColor : isSelected ? highlightColor : isFocused ? highlightColor : bgColor,
                    color: isDisabled ? '#ccc' : textColor,
                    ':before': {
                        backgroundColor: dataColor,
                        borderRadius: 10,
                        marginRight: '10px',
                        content: '" "',
                        display: 'inline-block',
                        height: '1rem',
                        width: 10,
                    },
                };
            },
        };

        return (
            <React.Fragment>
                <div className={this.props.history.loading ? 'filter filter--loading' : 'filter'}>
                    <div className="filter__greeting">
                        <h2 className="title title--h2">
                            Filtrera lista
                            {this.props.history.loading ? <Spinner /> : null}
                        </h2>
                        {this.state.filterIsDirty ? (
                            <button className="button button--link" onClick={this.clearFilter.bind(this)}>
                                <span>Rensa filter</span> <Icon name="trash" />
                            </button>
                        ) : null}
                    </div>
                    {this.state.filter ? (
                        <React.Fragment>
                            {this.state.filter.invoiceAmountRange.min &&
                            this.state.filter.invoiceAmountRange.max &&
                            this.props.history.aggregations.global.invoiceAmountRange.min !== this.props.history.aggregations.global.invoiceAmountRange.max ? (
                                <div className="filter__section">
                                    <div className="input input--small">
                                        <RangeSlider
                                            label="Fakturabelopp"
                                            resetSubject={this.doResetSubject}
                                            value={this.state.invoiceAmountRangeValue}
                                            min={this.state.filter.invoiceAmountRange.min}
                                            max={this.state.filter.invoiceAmountRange.max}
                                            startMin={this.props.history.aggregations.global.invoiceAmountRange.min}
                                            startMax={this.props.history.aggregations.global.invoiceAmountRange.max}
                                            callBack={this.handleFilterUpdate.bind(this, 'invoiceAmountRange', 'range')}
                                        />
                                    </div>
                                </div>
                            ) : null}
                            {this.props.history.aggregations.global.states && this.props.history.aggregations.global.states.length > 0 ? (
                                <div className="filter__section">
                                    <h3 className="title title--h3">Status</h3>
                                    <div className="filter__specific-state">
                                    <button
                                            className={
                                                this.state.activeStates.indexOf('activeUnpaid') > -1
                                                    ? 'button'
                                                    : 'button button--disabled'
                                            }
                                            onClick={this.toggleStateSpecific.bind(this, 'activeUnpaid', ['Clearing', 'Reminder1', 'DebtCollector', 'EnforcementAuthorityWarning'])}
                                        >
                                            Aktiva obetalda
                                        </button>
                                        <button
                                            className={
                                                this.state.activeStates.indexOf('pendingRepurchasing') > -1
                                                    ? 'button button--warning'
                                                    : 'button button--warning button--disabled'
                                            }
                                            onClick={this.toggleStateSpecific.bind(this, 'pendingRepurchasing', ['PendingRepurchasing'])}
                                        >
                                            Återförda
                                        </button>
                                    </div>
                                    <div className="filter__states">
                                        <button
                                            className={
                                                this.state.activeStates.indexOf('function') > -1
                                                    ? 'button button--chip button--function button--icon-only'
                                                    : 'button button--chip button--function button--icon-only button--disabled'
                                            }
                                            onClick={this.toggleState.bind(this, 'function')}
                                        >
                                            <Icon name="check" />
                                        </button>
                                        <button
                                            className={
                                                this.state.activeStates.indexOf('approve') > -1
                                                    ? 'button button--chip button--approve button--icon-only'
                                                    : 'button button--chip button--approve button--icon-only button--disabled'
                                            }
                                            onClick={this.toggleState.bind(this, 'approve')}
                                        >
                                            <Icon name="check" />
                                        </button>
                                        <button
                                            className={
                                                this.state.activeStates.indexOf('wait') > -1
                                                    ? 'button button--chip button--wait button--icon-only'
                                                    : 'button button--chip button--wait button--icon-only button--disabled'
                                            }
                                            onClick={this.toggleState.bind(this, 'wait')}
                                        >
                                            <Icon name="check" />
                                        </button>
                                        <button
                                            className={
                                                this.state.activeStates.indexOf('warning') > -1
                                                    ? 'button button--chip button--warning button--icon-only'
                                                    : 'button button--chip button--warning button--icon-only button--disabled'
                                            }
                                            onClick={this.toggleState.bind(this, 'warning')}
                                        >
                                            <Icon name="check" />
                                        </button>
                                    </div>
                                    <div className="filter__states filter__states--advanced">
                                        <button className="a" onClick={this.handleAdvancedClick}>
                                            {advanceLinkText}
                                        </button>
                                    </div>
                                    <div className="filter__states">
                                        {this.state.showAllStatuses ? (
                                            <React.Fragment>
                                                <h3 className="title title--h4">Vald status</h3>
                                                <Select
                                                    className="filter__states--select"
                                                    options={this.state.allStates}
                                                    value={this.state.filteredStates}
                                                    getOptionLabel={(option) => `${option.text}`}
                                                    getOptionValue={(option) => option['stateType']}
                                                    styles={colourStyles}
                                                    isMulti
                                                    isClearable
                                                    closeMenuOnSelect={false}
                                                    placeholder="Filtrera på status"
                                                    onChange={this.handleFilterStatusChange}
                                                    isOptionSelected={this.isOptionSelected}
                                                />
                                            </React.Fragment>
                                        ) : null}
                                    </div>
                                </div>
                            ) : null}
                            {this.state.filter.invoiceDate.to || this.state.filter.invoiceDate.from ? (
                                <div className="filter__section">
                                    <h3 className="title title--h3">Fakturadatum</h3>
                                    <DayPickerInput
                                        defaultDate={null}
                                        resetSubject={this.doResetSubject}
                                        label="Från"
                                        callBack={this.handleFilterUpdate.bind(this, 'invoiceDate', 'from')}
                                        disabledAfter={new Date(this.state.filter.invoiceDate.to)}
                                        disabledBefore={new Date(this.state.filter.invoiceDate.from)}
                                    />
                                    <DayPickerInput
                                        defaultDate={null}
                                        resetSubject={this.doResetSubject}
                                        label="Till"
                                        callBack={this.handleFilterUpdate.bind(this, 'invoiceDate', 'to')}
                                        disabledAfter={new Date(this.state.filter.invoiceDate.to)}
                                        disabledBefore={new Date(this.state.filter.invoiceDate.from)}
                                    />
                                </div>
                            ) : null}
                            {this.state.filter.dueDate.to || this.state.filter.dueDate.from ? (
                                <div className="filter__section">
                                    <h3 className="title title--h3">Förfallodatum</h3>
                                    <DayPickerInput
                                        defaultDate={null}
                                        resetSubject={this.doResetSubject}
                                        label="Från"
                                        callBack={this.handleFilterUpdate.bind(this, 'dueDate', 'from')}
                                        disabledAfter={new Date(this.state.filter.dueDate.to)}
                                        disabledBefore={new Date(this.state.filter.dueDate.from)}
                                    />
                                    <DayPickerInput
                                        defaultDate={null}
                                        resetSubject={this.doResetSubject}
                                        label="Till"
                                        callBack={this.handleFilterUpdate.bind(this, 'dueDate', 'to')}
                                        disabledAfter={new Date(this.state.filter.dueDate.to)}
                                        disabledBefore={new Date(this.state.filter.dueDate.from)}
                                    />
                                </div>
                            ) : null}
                            {this.state.filter.createdDate.to || this.state.filter.createdDate.from ? (
                                <div className="filter__section">
                                    <h3 className="title title--h3">Försäljningsdatum</h3>
                                    <DayPickerInput
                                        defaultDate={null}
                                        resetSubject={this.doResetSubject}
                                        label="Från"
                                        callBack={this.handleFilterUpdate.bind(this, 'createdDate', 'from')}
                                        disabledAfter={new Date(this.state.filter.createdDate.to)}
                                        disabledBefore={new Date(this.state.filter.createdDate.from)}
                                    />
                                    <DayPickerInput
                                        defaultDate={null}
                                        resetSubject={this.doResetSubject}
                                        label="Till"
                                        callBack={this.handleFilterUpdate.bind(this, 'createdDate', 'to')}
                                        disabledAfter={new Date(this.state.filter.createdDate.to)}
                                        disabledBefore={new Date(this.state.filter.createdDate.from)}
                                    />
                                </div>
                            ) : null}
                        </React.Fragment>
                    ) : null}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        history: state.history,
        stateMapper: stateMapper,
    };
};

HistoryInvoiceFilter = connect(mapStateToProps, {
    historyFilter,
})(HistoryInvoiceFilter);

export default HistoryInvoiceFilter;
